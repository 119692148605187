// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import { updateWebsiteState as updateWebsiteStateFx } from '../state/actions'

import AboutPageSchema from '../components/schema/about-page-schema'
import ContactPageSchema from '../components/schema/contact-page-schema'

import Slider from '../components/slider'
import '../components/slider/style.less'

import about from '../seo/about.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'About',
  slug: '/about',
  abstract: about.text,
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'About', slug: '/about' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(relativePath: { eq: "book/img1.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img2: file(relativePath: { eq: "book/img2.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img3: file(relativePath: { eq: "book/img3.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img4: file(relativePath: { eq: "book/img4.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img5: file(relativePath: { eq: "book/img5.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img6: file(relativePath: { eq: "book/img6.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img7: file(relativePath: { eq: "book/img7.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img8: file(relativePath: { eq: "book/img8.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img9: file(relativePath: { eq: "book/img9.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img10: file(relativePath: { eq: "book/img10.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ websiteState, updateWebsiteState, ...props }) => {
  const nodes = [
    { img: props.data.img1 },
    { img: props.data.img2 },
    { img: props.data.img3 },
    { img: props.data.img4 },
    { img: props.data.img5 },
    { img: props.data.img6 },
    { img: props.data.img7 },
    { img: props.data.img8 },
    { img: props.data.img9 },
    { img: props.data.img10 },
  ]

  return (
    <StandardPageWrapper pageSchema={pageSchema} {...props}>
      <AboutPageSchema />
      <ContactPageSchema />
      <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
        <h1>About this book</h1>
        <p>{about.text}</p>
        <Slider
          className="vh45 ext-arrow-opaque ext-dots"
          conf={{
            dots: true,
          }}
          nodes={nodes}
          Renderer={({ node }) => (
            <GatsbyImage image={getImage(node.img)} objectFit="contain" />
          )}
        />
        <h2>Open-access format</h2>
        <p>
          <i>{about.altTitle}</i>&nbsp;is published as part of an emerging
          method of publishing being developed by Prisma Books. The idea is to
          deliver the high level of writing and design quality that you would
          find in a printed book, and to use advanced web technologies for
          publishing. This is our "open-access" format.
        </p>
        <p>
          <Link to="https://books.prisma.haus/en/web-books">
            Read more about web books →
          </Link>
        </p>
        <h2 id="on-gift-economy">On gift economy</h2>
        <p>
          You can buy the paperback version of the book <i>{about.altTitle}</i>{' '}
          at a fixed price. The author and the publisher have also chosen to
          offer the book to read online without a pay-wall or advertisement.
          This is not for free, but available on a Gift-Economy and
          Pay-What-Feels-Right basis. It is an invitation to give what feels
          right for the value you've received from the book and/or the support
          you want to give us to continue doing our work. Publishing in this
          manner is an experiment in abundance where we trust that when we give,
          we will also receive. We want you to enjoy the book, learn from the
          book, but also support the book. If it was worth your time, please
          consider buying the book or contributing.
        </p>
        <p>
          <Link to="https://books.prisma.haus/en/available-on-gift-economy">
            Learn more about publishing on Gift-Economy →
          </Link>
        </p>
        <h2>Prisma Books, the publisher</h2>
        <p>
          Since 1988 Prisma Books of Auroville publishes good old-fashioned,
          beautifully curated books. Prisma Books offers full publishing
          services in design, editorial, printing, artwork, illustrations,
          photography, digital media, exhibition and curatorship.
        </p>
        <p>
          <Link to="https://www.prisma.haus/en/">Prisma website →</Link>
        </p>
      </div>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    websiteState: state.websiteState,
  }),
  (dispatch) => ({
    updateWebsiteState(payload) {
      dispatch(updateWebsiteStateFx(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
